import React from 'react';

const GlobalStyles = () => (
  <style jsx global>{`
    @import url('https://fonts.googleapis.com/css2?family=Geist:wght@100..900&display=swap');

    :root {
      --font-size-min: 14;
      --font-size-max: 20;
      --font-ratio-min: 1.1;
      --font-ratio-max: 1.33;
      --font-width-min: 375;
      --font-width-max: 1500;
      --saturation: 60%;
      --lightness-min: 70%;
      --lightness-max: 90%;
      --size: 45px;
      --line: rgba(255, 255, 255, 0.3);
      --line-height: 1.5; /* Default line height */
    }

    html,
    body {
      min-height: 100vh;
      margin: 0;
      padding: 0;
      background: black;
      color-scheme: dark;
    }

    body {
      display: grid;
      place-items: center;
      font-family: 'Geist', 'SF Pro Text', 'SF Pro Icons', 'AOS Icons',
        'Helvetica Neue', Helvetica, Arial, sans-serif, system-ui;
      position: relative;
      overflow-x: hidden;
    }

    .app {
      position: relative;
      width: 100%;
      min-height: 100vh;
    }

    .app::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: 
        linear-gradient(90deg, var(--line) 1px, transparent 1px var(--size)) 50% 50% / var(--size) var(--size),
        linear-gradient(var(--line) 1px, transparent 1px var(--size)) 50% 50% / var(--size) var(--size);
      mask: linear-gradient(-20deg, transparent 50%, white);
      pointer-events: none;
    }

    html {
      scroll-snap-type: y proximity;
    }

    *,
    *:after,
    *:before {
      box-sizing: border-box;
    }

    .fluid {
      --fluid-min: calc(
        var(--font-size-min) * pow(var(--font-ratio-min), var(--font-level, 0))
      );
      --fluid-max: calc(
        var(--font-size-max) * pow(var(--font-ratio-max), var(--font-level, 0))
      );
      --fluid-preferred: calc(
        (var(--fluid-max) - var(--fluid-min)) /
        (var(--font-width-max) - var(--font-width-min))
      );
      --fluid-type: clamp(
        (var(--fluid-min) / 16) * 1rem,
        ((var(--fluid-min) / 16) * 1rem) +
        (var(--fluid-preferred) * (100vw - (var(--font-width-min) * 1px))),
        (var(--fluid-max) / 16) * 1rem
      );
      font-size: var(--fluid-type);
    }

    main section:first-of-type {
      --font-level: 6;
      display: flex;
      line-height: 1.25;
      width: 100%;
      padding-left: 5rem;
    }

    main section:first-of-type h2 {
      position: sticky;
      top: calc(50% - 0.75rem); /* Assuming 1lh ≈ 1.5rem */
      font-size: inherit;
      margin: 0;
      display: inline-block;
      height: fit-content;
      font-weight: 600;
    }

    ul {
      --step-hue: calc((360 - 0) / (var(--count) - 2));
      --start-hue: 0;
      font-weight: 600;
      padding-inline: 0;
      margin: 0;
      list-style-type: none;
    }

    li {
      scroll-snap-align: center;
      transform-origin: left center;
      will-change: transform, opacity;
    }

    li:not(:last-of-type) {
      color: hsl(
        calc(var(--start-hue) + (var(--step-hue) * var(--i))),
        var(--saturation),
        calc(var(--lightness-min) + ((var(--i) / (var(--count) - 1)) * (var(--lightness-max) - var(--lightness-min))))
      );
      transition: color 0.3s ease, transform 0.3s ease-out, opacity 0.3s ease-out;
    }

    li:last-of-type {
      color: hsl(0, 0%, 100%);
      font-weight: 700;
    }

    @keyframes brighten {
      0%,
      100% {
        opacity: 0.2;
      }
      50% {
        opacity: 1;
        filter: brightness(1.2);
      }
    }

    h2,
    li:last-of-type {
      background: linear-gradient(
        canvasText 50%,
        color-mix(in oklch, canvas, canvasText 25%)
      );
      background-clip: text;
      color: transparent;
    }

    main section:last-of-type {
      min-height: 100vh;
      display: flex;
      place-items: center;
      width: 100%;
      justify-content: center;
    }

    main section:last-of-type h2 {
      --font-level: 6;
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }

    @media (prefers-color-scheme: dark) {
      :root {
        --lightness-max: 85%;
      }
    }
  `}</style>
);

export default GlobalStyles;