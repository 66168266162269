// components/ScrollIndicator.jsx
import React from 'react';

const ScrollIndicator = () => {
  return (
    <div className="scroll-indicator">
      <div className="mouse" />
      <style jsx>{`
        .scroll-indicator {
          position: absolute;
          bottom: 2rem;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          animation: fadeIn 1s ease-out forwards;
          animation-delay: 1s;
        }
        .mouse {
          width: 24px;
          height: 40px;
          border: 2px solid rgba(255, 255, 255, 0.7);
          border-radius: 12px;
          position: relative;
        }
        .mouse::before {
          content: '';
          width: 4px;
          height: 8px;
          background: rgba(255, 255, 255, 0.7);
          position: absolute;
          top: 6px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 2px;
          animation: scroll 1.5s infinite;
        }
        @keyframes scroll {
          0% {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
          }
          100% {
            opacity: 0;
            transform: translateX(-50%) translateY(12px);
          }
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default ScrollIndicator;