import React, { useState, useEffect, useRef } from 'react';
import { Monitor, Smartphone, Globe2, Bot, Paintbrush, Layout, ArrowRight, Users, Code, Award } from 'lucide-react';

const WeCanSection = () => {
  const items = [
    'design.',
    'develop.',
    'prototype.',
    'solve.',
    'build.',
    'accelerate.',
    'transform.',
    'scale.',
    'optimize.',
    'ship.',
    'deliver.',
    'INNOVATE.',
  ];

  const [activeItems, setActiveItems] = useState(new Set());
  const itemRefs = useRef([]);

  useEffect(() => {
    const observers = [];
    
    itemRefs.current.forEach((ref, index) => {
      if (ref) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setActiveItems((prev) => new Set([...prev, index]));
              } else {
                setActiveItems((prev) => {
                  const newSet = new Set(prev);
                  newSet.delete(index);
                  return newSet;
                });
              }
            });
          },
          {
            threshold: 0.5,
            rootMargin: '-45% 0px -45% 0px',
          }
        );

        observer.observe(ref);
        observers.push(observer);
      }
    });

    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, []);

  const services = [
    {
      icon: Globe2,
      title: 'Web Application Development',
      description:
        'Crafting responsive, optimized web applications tailored to meet unique business needs, ensuring seamless user experiences across all devices.',
    },
    {
      icon: Smartphone,
      title: 'Cross-Platform Development',
      description:
        'Building versatile applications that integrate seamlessly across various platforms, maximizing accessibility and engagement for users.',
    },
    {
      icon: Layout,
      title: 'Translation & Localization',
      description:
        'Enhancing accessibility by adapting educational content for diverse languages and cultural contexts, ensuring inclusivity.',
    },
    {
      icon: Bot,
      title: 'AI-Driven Accelerators',
      description:
        'Leveraging AI technology to streamline processes, enhance analytics, and automate repetitive tasks, boosting productivity and insights.',
    },
    {
      icon: Paintbrush,
      title: 'UI/UX Design',
      description:
        'Crafting intuitive and visually appealing interfaces that provide smooth, accessible, and engaging experiences for users.',
    },
    {
      icon: Monitor,
      title: 'Digital Content Development',
      description:
        'Developing digital textbooks, multimedia lessons, interactive exercises, and simulations to enhance learning and engagement.',
    },
  ];

  const containerRef = useRef(null);
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const startPos = window.scrollY + rect.top;
        setScrollPos(window.scrollY - startPos);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const segmentHeight = (typeof window !== 'undefined') ? window.innerHeight : 1;

  return (
    <main className="w-full bg-black">
      {/* "We can ..." section */}
      <section className="content fluid flex leading-[1.25] w-full pl-20 mb-0">
        <h2 className="sticky top-[calc(50%-0.75rem)] text-inherit m-0 inline-block h-fit font-semibold">
          <span aria-hidden="true">we can&nbsp;</span>
        </h2>
        <ul
          aria-hidden="true"
          style={{ '--count': items.length }}
          className="font-semibold p-0 m-0 list-none"
        >
          {items.map((item, index) => (
            <li
              key={index}
              ref={(el) => (itemRefs.current[index] = el)}
              style={{
                '--i': index,
                transform: activeItems.has(index) ? 'scale(1.1)' : 'scale(1)',
                transition: 'transform 0.3s ease-out',
              }}
              className={`transition-all duration-300 ease-out ${
                activeItems.has(index) ? 'opacity-100' : 'opacity-50'
              }`}
            >
              {item}
            </li>
          ))}
        </ul>
      </section>

      {/* Stacked Services Section */}
      <section
        ref={containerRef}
        className="relative"
        style={{ height: `${services.length * 100}vh`, marginTop: '10vh' }}
      >
        <div className="sticky top-0 h-screen w-full flex items-center justify-center overflow-hidden">
          {services.map((service, index) => {
            const offset = scrollPos / segmentHeight;
            const diff = index - offset;
            const scale = Math.max(0.8, 1 - Math.abs(diff) * 0.1);
            const opacity = Math.max(0, 1 - Math.abs(diff) * 0.7);
            const translateX = diff * 400;
            const zIndex = services.length - Math.abs(diff);

            return (
              <div
                key={index}
                className="absolute flex flex-col items-center justify-center text-center p-8 rounded-3xl backdrop-blur-lg"
                style={{
                  width: '400px',
                  height: '480px',
                  transform: `translateX(${translateX}px) scale(${scale})`,
                  opacity,
                  zIndex,
                  transition: 'all 0.3s ease-out',
                  background: 'linear-gradient(145deg, rgba(19, 24, 32, 0.9), rgba(26, 32, 44, 0.9))',
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                }}
              >
                <div className="mb-8 p-4 rounded-full bg-gradient-to-br from-gray-800 to-gray-900">
                  <service.icon className="w-12 h-12 text-blue-400" />
                </div>
                <h3 className="text-2xl font-bold mb-6 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                  {service.title}
                </h3>
                <p className="text-lg text-gray-300 leading-relaxed">
                  {service.description}
                </p>
              </div>
            );
          })}
        </div>
      </section>

      {/* Impact & Contact Section */}
      <section className="py-24 px-8 bg-gradient-to-b from-transparent to-[rgba(19,24,32,0.9)]">
        <div className="max-w-7xl mx-auto">
          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            <div className="flex flex-col items-center p-6 rounded-2xl bg-[rgba(255,255,255,0.03)] border border-[rgba(255,255,255,0.1)]">
              <Users className="w-8 h-8 mb-4 text-blue-400" />
              <span className="text-4xl font-bold bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent mb-2">15+</span>
              <span className="text-gray-400 text-center">Years Combined Experience</span>
            </div>
            <div className="flex flex-col items-center p-6 rounded-2xl bg-[rgba(255,255,255,0.03)] border border-[rgba(255,255,255,0.1)]">
              <Code className="w-8 h-8 mb-4 text-blue-400" />
              <span className="text-4xl font-bold bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent mb-2">50+</span>
              <span className="text-gray-400 text-center">Projects Completed</span>
            </div>
            <div className="flex flex-col items-center p-6 rounded-2xl bg-[rgba(255,255,255,0.03)] border border-[rgba(255,255,255,0.1)]">
              <Award className="w-8 h-8 mb-4 text-blue-400" />
              <span className="text-4xl font-bold bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent mb-2">100%</span>
              <span className="text-gray-400 text-center">Client Project Success Rate</span>
            </div>
          </div>

          {/* Contact Section */}
          <div className="flex flex-col lg:flex-row gap-12 items-center lg:items-start">
            {/* Left side content */}
            <div className="flex-1 text-left">
              <h2 className="text-3xl md:text-4xl font-bold mb-6 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                Fresh Perspective, <br />Deep Expertise
              </h2>
              <p className="text-lg text-gray-400 mb-8">
                Our team brings years of industry experience and a track record of delivering exceptional results. We combine proven expertise with innovative thinking to create solutions that drive real impact.
              </p>
              <ul className="space-y-4 text-gray-400">
                <li className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-blue-400"></div>
                  Expert team with diverse tech background
                </li>
                <li className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-blue-400"></div>
                  Agile, responsive, and personalized approach
                </li>
                <li className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-blue-400"></div>
                  Commitment to innovation and excellence
                </li>
              </ul>
            </div>

            {/* Contact Form */}
            <div className="flex-1 w-full max-w-md p-8 rounded-2xl bg-[rgba(255,255,255,0.03)] border border-[rgba(255,255,255,0.1)]">
              <h3 className="text-2xl font-semibold mb-6 text-white">Let's Connect</h3>
              <form className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-400 mb-2">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="w-full px-4 py-2 rounded-lg bg-[rgba(255,255,255,0.05)] border border-[rgba(255,255,255,0.1)] text-white focus:outline-none focus:border-blue-400 transition-colors"
                    placeholder="Your name"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-400 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="w-full px-4 py-2 rounded-lg bg-[rgba(255,255,255,0.05)] border border-[rgba(255,255,255,0.1)] text-white focus:outline-none focus:border-blue-400 transition-colors"
                    placeholder="your@email.com"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-400 mb-2">
                    Message
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    className="w-full px-4 py-2 rounded-lg bg-[rgba(255,255,255,0.05)] border border-[rgba(255,255,255,0.1)] text-white focus:outline-none focus:border-blue-400 transition-colors"
                    placeholder="Tell us about your project"
                  ></textarea>
                </div>
                <button 
                  type="submit"
                  className="w-full px-8 py-3 rounded-full bg-gradient-to-r from-blue-500 to-blue-700 text-white font-semibold hover:from-blue-600 hover:to-blue-800 transition-all duration-300 flex items-center justify-center group"
                >
                  Send Message
                  <ArrowRight className="w-4 h-4 ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default WeCanSection;