import React from 'react';
import { Github, Linkedin, Twitter, Mail } from 'lucide-react';

const Footer = () => (
  <footer className="py-12 px-8 border-t border-[rgba(255,255,255,0.1)] bg-[rgba(19,24,32,0.9)]">
    <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center gap-8">
      <div className="flex flex-col items-center md:items-start">
        <span className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent mb-2">
          NexSav
        </span>
        <p className="text-gray-400 text-sm">
          Transforming ideas into digital realities
        </p>
      </div>
      
      <div className="flex gap-6">
        <a href="https://github.com/yourusername" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-blue-400 transition-colors">
          <Github className="w-5 h-5" />
        </a>
        <a href="https://linkedin.com/in/yourusername" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-blue-400 transition-colors">
          <Linkedin className="w-5 h-5" />
        </a>
        <a href="https://twitter.com/yourusername" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-blue-400 transition-colors">
          <Twitter className="w-5 h-5" />
        </a>
        <a href="mailto:contact@nexsav.com" className="text-gray-400 hover:text-blue-400 transition-colors">
          <Mail className="w-5 h-5" />
        </a>
      </div>
      
      <div className="text-gray-400 text-sm">
        © {new Date().getFullYear()} NexSav. All rights reserved.
      </div>
    </div>
  </footer>
);

export default Footer;