import React from 'react';
import logo from '../assets/logo.png';

const SplashScreen = ({ fadeOut }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black transition-opacity duration-1000 ${
        fadeOut ? 'opacity-0' : 'opacity-100'
      }`}
      style={{ zIndex: 50 }}
    >
      <img src={logo} alt="NexSav Logo" className="h-24 sm:h-32" />

      <style jsx>{`
        .fixed {
          position: fixed;
        }
        .inset-0 {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
        .flex {
          display: flex;
        }
        .items-center {
          align-items: center;
        }
        .justify-center {
          justify-content: center;
        }
        .bg-black {
          background-color: black;
        }
        .transition-opacity {
          transition-property: opacity;
        }
        .duration-1000 {
          transition-duration: 1000ms;
        }
        .opacity-0 {
          opacity: 0;
        }
        .opacity-100 {
          opacity: 1;
        }
        .h-24 {
          height: 6rem;
        }
        @media (min-width: 640px) {
          .sm:h-32 {
            height: 8rem;
          }
        }
      `}</style>
    </div>
  );
};

export default SplashScreen;