// components/Header.jsx
import React, { useEffect, useRef } from 'react';
import ScrollIndicator from './ScrollIndicator';

const Header = () => {
  const headerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!headerRef.current) return;
      const { clientX, clientY } = e;
      const { width, height, left, top } = headerRef.current.getBoundingClientRect();
      const x = (clientX - left) / width;
      const y = (clientY - top) / height;
      
      headerRef.current.style.setProperty('--mouse-x', x.toFixed(2));
      headerRef.current.style.setProperty('--mouse-y', y.toFixed(2));
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const createCharSpans = (text) => {
    return text.split('').map((char, index) => (
      <span
        key={index}
        className="char"
        data-char={char}
        style={{ '--char-index': index }}
      >
        {char}
      </span>
    ));
  };

  return (
    <header ref={headerRef}>
      <div className="content">
        <h1 className="fluid">
          <div className="title-container">
            <span className="nex glitch-wrapper">{createCharSpans('Nex')}</span>
            <span className="sav glitch-wrapper">{createCharSpans('Sav')}</span>
          </div>
        </h1>
        <p className="slogan">Innovating Tomorrow's Technology Today</p>
        <div className="glow" />
      </div>
      <ScrollIndicator />
      <style jsx>{`
        header {
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          padding-inline: 5rem;
          overflow: hidden;
        }

        .content {
          text-align: center;
          position: relative;
          z-index: 1;
        }

        .glow {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 120%;
          height: 120%;
          transform: translate(-50%, -50%);
          background: radial-gradient(
            circle at calc(var(--mouse-x, 0.5) * 100%) calc(var(--mouse-y, 0.5) * 100%),
            rgba(64, 223, 255, 0.15) 0%,
            rgba(64, 223, 255, 0) 50%
          );
          pointer-events: none;
          z-index: -1;
          transition: all 0.1s ease;
        }

        h1 {
          --font-size-min: 24;
          --font-level: 8;
          text-wrap: pretty;
          line-height: 0.8;
          margin: 0;
          perspective: 1000px;
        }

        .title-container {
          display: flex;
          justify-content: center;
          gap: 0.1em;
        }

        .glitch-wrapper {
          display: inline-block;
          position: relative;
          animation: float 6s ease-in-out infinite;
        }

        .nex {
          animation-delay: -3s;
        }

        .char {
          display: inline-block;
          background: linear-gradient(
            45deg,
            #fff 10%,
            #40dfff 45%,
            #fff 75%
          );
          background-size: 200% auto;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          text-shadow: 0 0 30px rgba(64, 223, 255, 0.5);
          position: relative;
        }

        .char::before,
        .char::after {
          content: attr(data-char);
          position: absolute;
          left: 0;
          opacity: 0.7;
          filter: blur(0.02em);
          pointer-events: none;
        }

        .char::before {
          animation: glitch-top 1.5s calc(var(--char-index) * 0.2s) infinite linear alternate-reverse;
          clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
          background: inherit;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }

        .char::after {
          animation: glitch-bottom 1.5s calc(var(--char-index) * 0.2s) infinite linear alternate-reverse;
          clip-path: polygon(0 55%, 100% 55%, 100% 100%, 0 100%);
          background: inherit;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }

        @keyframes glitch-top {
          0%, 90% {
            transform: translate(0);
            opacity: 0;
          }
          95% {
            transform: translate(-0.15em, -0.05em);
            opacity: 0.7;
          }
          100% {
            transform: translate(0);
            opacity: 0;
          }
        }

        @keyframes glitch-bottom {
          0%, 90% {
            transform: translate(0);
            opacity: 0;
          }
          95% {
            transform: translate(0.15em, 0.05em);
            opacity: 0.7;
          }
          100% {
            transform: translate(0);
            opacity: 0;
          }
        }

        @keyframes float {
          0%, 100% {
            transform: translateY(0) rotateX(0deg);
          }
          50% {
            transform: translateY(-10px) rotateX(5deg);
          }
        }

        .slogan {
          font-size: 1.5rem;
          margin-top: 2rem;
          opacity: 0;
          transform: translateY(20px);
          animation: fadeUp 0.8s ease-out forwards, shimmer 3s ease-in-out infinite;
          animation-delay: 0.5s, 1s;
          background: linear-gradient(
            to right,
            #ffffff,
            #40dfff,
            #ffffff
          );
          background-size: 200% auto;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
        }

        @keyframes shimmer {
          0% {
            background-position: -100% center;
          }
          100% {
            background-position: 200% center;
          }
        }

        @keyframes fadeUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 0.9;
            transform: translateY(0);
          }
        }

        @media (max-width: 768px) {
          header {
            padding-inline: 2rem;
          }
          
          h1 {
            --font-level: 7;
          }
          
          .slogan {
            font-size: 1.2rem;
          }
        }
      `}</style>
    </header>
  );
};

export default Header;